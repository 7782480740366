<template>
  <div class="settings">
    <v-row>
      <v-col cols="12">
        <v-card
          class="pa-10"
          style="border-radius: 30px"
          color="white"
          elevation="0"
        >
          <h3 class="primary--text">{{ $t("changePassword") }}</h3>
            <v-row class="mt-6">
              <v-col cols="6">
                <a-text-field
                  
                  :label="$t('currentPassword') + $t('required')"
                  v-model="$v.currentPassword.$model"
                  :validator="$v.currentPassword"
                  type="password"
                  :outlined="'outlined'"
                  class="pb-4"
                >
                </a-text-field>
                <a-text-field
                  :label="$t('newPassword') + $t('required')"
                  v-model="$v.newPassword.$model"
                  :validator="$v.newPassword"
                  type="password"
                  :outlined="'outlined'"
                  class="pb-4"
                >
                </a-text-field>

                <a-text-field
                  :label="$t('newPasswordConfirm') + $t('required')"
                  v-model="$v.newPasswordConfirm.$model"
                  :validator="$v.newPasswordConfirm"
                  :outlined="'outlined'"
                  type="password"
                >
                </a-text-field>
              </v-col>
            </v-row>
            <v-row class="ml-0 mt-8">
                <v-btn
                  color="primary"
                  
                  @click="changePassword"
                  class="mb-n6 mr-n2"
                >
                  {{ $t("changePassword") }}
                </v-btn>
            </v-row>
            <v-row class="mt-12">
              <v-col cols="3" class="mt-4">
                <h3 class="primary--text">{{ $t("changeLanguage") }}</h3>
                <v-select
                  v-model="selectedLanguage"
                  :items="availableLanguages"
                  :label="$t('language')"
                  prepend-icon="mdi-translate"
                  class="language mt-10"
                  hide-details
                  dense
                  
                ></v-select>
              </v-col>
            </v-row>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style scoped lang="scss">
.v-btn {
  text-transform: unset !important;
}
</style>
<script>
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import AppError from "@/utils/AppError.js";
import ATextField from "../components/ATextField.vue";
import { loadLanguageAsync } from "@/plugins/i18n";

export default {
  name: "Settings",

  components: {
    ATextField,
  },

  mixins: [validationMixin],

  validations: {
    // Form validations
    currentPassword: { required },
    newPassword: { required },
    newPasswordConfirm: { required },
  },

  mounted: function () {
    this.selectedLanguage = localStorage.getItem("language") || "en";
  },

  data() {
    return {
      // Form data
      currentPassword: null,
      newPassword: null,
      newPasswordConfirm: null,
      formInputLanguage: null,
      selectedLanguage: null,
    };
  },

  watch: {
    selectedLanguage() {
      localStorage.setItem("language", this.selectedLanguage);
      this.$store.dispatch("util/setLanguage", this.selectedLanguage);

      // Set app language
      loadLanguageAsync(this.selectedLanguage);
    },
  },

  computed: {
    // Available languages
    availableLanguages() {
      return [
        {
          value: "pt",
          text: this.$t("languages.portuguese"),
        },
        {
          value: "en",
          text: this.$t("languages.english"),
        },
      ];
    },
  },

  methods: {
    /**
     * Reset change password form.
     * @public
     */
    resetChangePasswordForm() {
      this.$v.currentPassword.$reset();
      this.$v.newPassword.$reset();
      this.$v.newPasswordConfirm.$reset();
      this.currentPassword = null;
      this.newPassword = null;
      this.newPasswordConfirm = null;
    },
    /**
     * Change password.
     * @public
     */
    changePassword() {
      this.$v.currentPassword.$touch();
      this.$v.newPassword.$touch();
      this.$v.newPasswordConfirm.$touch();
      if (
        this.$v.currentPassword.$invalid ||
        this.$v.newPassword.$invalid ||
        this.$v.newPasswordConfirm.$invalid
      ) {
        return;
      }
      if (this.newPassword != null && this.newPasswordConfirm != null) {
        if (this.newPassword != this.newPasswordConfirm) {
          this.$store.dispatch(
            "alert/showError",
            this.$t("passwordsDontMatch")
          );
        } else {
          this.$store.dispatch("util/startLoading");
          let url = this.$config.api.route.importFiles;

          this.$api({
            url: url,
            data: {
              password: this.newPasswordConfirm,
            },
            method: "PUT",
          })
            .then(() => {
              this.$store.dispatch(
                "alert/showSuccess",
                "Password changed successfully"
              );
            })
            .catch((err) => {
              AppError.handle(err);
            })
            .finally(() => {
              this.$store.dispatch("util/stopLoading");
              //this.logout();
            });
        }
      }
    },
  },
};
</script>
